import React from "react"
import {FormattedMessage} from "react-intl";
import PricingPlans from "./pricing-plans.component";




const PricingSlide = () => {
    return (
        <div className='pricingSlide slide' id='priceSlide'>
            <div className="mainContainer">
                <div className="textPart">
                    {/* <div className="subTitle mb15 text-center">
                        <FormattedMessage id='pricing' defaultMessage='Pricing'/>
                    </div> */}
                    <h1 className='mb15 text-center'>
                        <FormattedMessage id='simple-plans' defaultMessage='Simple plans that grow with your business'/>
                    </h1>

                    <ul className="pricingFeature">
                        <li>
                            <h3 className="pricingFeatureTitle">100 <FormattedMessage id='sms' defaultMessage='free SMS'/></h3>
                            <p className="pricingFeatureDesc"><FormattedMessage id='100-fre-sms' defaultMessage='100 fre sms'/></p>
                        </li>
                        <li>
                            <h3 className="pricingFeatureTitle">0.73 <FormattedMessage id='uah1' defaultMessage='uah'/></h3>
                            <p className="pricingFeatureDesc"><FormattedMessage id='one-sms' defaultMessage='cost of one SMS'/></p>
                        </li>
                        <li>
                            <h3 className="pricingFeatureTitle"><FormattedMessage id='1-month' defaultMessage='1 month'/></h3>
                            <p className="pricingFeatureDesc"><FormattedMessage id='trial-period' defaultMessage='trial period'/></p>
                        </li>
                    </ul>

                    {/*<div className="coloredSubTitleHolder mb45">*/}
                    {/*    <div className="coloredSubTitle">*/}
                    {/*        <FormattedMessage id='enjoy free' defaultMessage='Enjoy free 100 sms and phone lookup every month'/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <PricingPlans/>
                </div>
            </div>
        </div>
    )
}

export default PricingSlide