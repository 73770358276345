import React from "react"
import {FormattedMessage} from "react-intl";


const Facts = [
    {
        id: 1,
        qnt: '99%',
        text: <FormattedMessage id='of-consumers-use' defaultMessage='99% of consumers use email every single day'/>
    },
    {
        id: 2,
        qnt: '59%',
        text: <FormattedMessage id='of-marketers-cite' defaultMessage='59% of marketers cite email as their number one source of ROI'/>
    },
    {
        id: 3,
        qnt: '98%',
        text: <FormattedMessage id='of-consumers-open' defaultMessage='98% of consumers open SMS received through campaigns'/>
    },
    {
        id: 4,
        qnt: '75%',
        text: <FormattedMessage id='of-individuals-like' defaultMessage='75% of individuals like offers to be sent via SMS'/>
    }
]

const FactsSlide = () => {
    return (
        <div className='factsSlide slide'>
            <div className="mainContainer sm">
                <div className="factsListHolder">
                    <ul className="factsList">
                        {
                            Facts.map((item) =>
                                <li className="flItem" key={item.id}>
                                    <div className="qntHolder">
                                        <div className="qnt">{item.qnt}</div>
                                        <div className="subQnt">{item.qnt}</div>
                                    </div>
                                    <div className="text">{item.text}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default FactsSlide