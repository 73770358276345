import React from "react"
import {graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image"
import {FormattedMessage} from "react-intl";


const IntroSlide = () => (
    <StaticQuery
        query={graphql`
          query {
            intro: file(relativePath: { eq: "1.png" }) {
              childImageSharp {
                fluid(maxWidth: 1912, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            introSlideBg: file(relativePath: { eq: "wave.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={(data) => {
            const imageData = data.introSlideBg.childImageSharp.fluid
            return (
                <div className='introSlide slide'>
                    <div className="mainContainer xs">
                        <div className="slideText">
                            <h1 className='mb30'>
                              <FormattedMessage id='deliver' defaultMessage='Deliver a message right to the'/>
                               <br/>
                              <span className="textPrimary"><FormattedMessage id='customers-pocket' defaultMessage='customers pocket'/></span>
                            </h1>
                            <div className="introImgHolder mobile">
                                <Img fluid={data.intro.childImageSharp.fluid}/>
                            </div>
                            <p className="textGray mb30">
                              <FormattedMessage id='easy-to-use' defaultMessage='An easy-to-use tool that allows you to spice up sales directly from your desktop and saves valuable time to grow your business'/>
                            </p>
                            <div className="btnHolder">
                                <a href="https://app.leads.com.ua/register" rel="noreferrer" target='_blank' className="btn btnPrimary contained">
                                  <FormattedMessage id='start-today' defaultMessage="Try for free"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mainContainer sm">
                        <div className="introImgHolder desktop">
                            <Img fluid={data.intro.childImageSharp.fluid}/>
                        </div>
                    </div>
                </div>
            )
        }}
    />
)

export default IntroSlide
