import React from "react"
import Fli1 from "../../../assets/img/id_card.svg";
import Fli2 from "../../../assets/img/mail_open2.svg";
import Fli3 from "../../../assets/img/mail_open.svg";
import Scr1 from "../../../assets/img/2.png"
import {Link as ScrollLink} from "react-scroll";
import {FormattedMessage} from "react-intl";

const Features = [
    {
        id: 1,
        img: Fli1,
        title: <FormattedMessage id='add-leads-with-ease' defaultMessage='Add leads with ease'/>,
        text: <FormattedMessage id='upload-leads' defaultMessage='Upload leads using CSV files in seconds.'/>
    },
    {
        id: 2,
        img: Fli2,
        title: <FormattedMessage id='custom-automated-campaigns' defaultMessage='Custom automated campaigns'/>,
        text: <FormattedMessage id='scale-your-outreach' defaultMessage='Scale your outreach with personalized SMS/email campaigns. Whether you want to speed up your cold outreach, schedule campaigns for existing leads, or modify follow-ups.'/>
    },
    {
        id: 3,
        img: Fli3,
        title: <FormattedMessage id='automate-message-outreach' defaultMessage='Automate Message Outreach'/>,
        text: <FormattedMessage id='increase-involvement' defaultMessage='Increase involvement with effective automated outreach to achieve your leads whenever.'/>
    }
]

const AboutSlide = () => {
    return (
        <div className='aboutSlide slide' id='aboutSlide'>
            <div className="mainContainer">
                <div className="slideWrapper">
                    <div className="textPart">
                        <div className="subTitle mb15"><FormattedMessage id='about' defaultMessage='About'/></div>
                        <h1 className='mb15'><FormattedMessage id='simplify-and-effectivise-your-work' defaultMessage='Simplify and effectivise your work'/></h1>
                        <div className="imgHolder mobile">
                            <img src={Scr1} alt=""/>
                        </div>
                        <p className='mb30'>
                            <FormattedMessage id='spend-your-valuable-time' defaultMessage='pend your valuable time on growing your business. Leads is an easy-to-use and intuitive tool that does not require extra onboarding. Create an "ideal audience" and start boosting your sales in minutes.'/>
                        </p>
                        <div className="featuresListHolder">
                            <ul className="featuresList">
                                {
                                    Features.map((item) =>
                                        <li className="flItem" key={item.id}>
                                            <div className="icoHolder"><img src={item.img} alt={item.title + ' Icon'}/></div>
                                            <div className="textHolder">
                                                <div className="title">{item.title}</div>
                                                <div className="text">{item.text}</div>
                                            </div>
                                        </li>
                                    )
                                }

                            </ul>
                        </div>
                        <div className="btnHolder">
                            <ScrollLink to="keySlide" smooth={true} className="btn btnPrimary contained">
                                <FormattedMessage id='and-a-lot-more' defaultMessage="And a lot more"/>
                            </ScrollLink>
                        </div>
                    </div>
                    <div className="imgPart">
                        <div className="imgHolder desktop">
                            <img src={Scr1} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSlide