import React from "react"
import {FormattedMessage} from "react-intl";
import Visa from "../../../assets/img/visa.png"
import Mc from "../../../assets/img/mc.png"
import Liqpay from "../../../assets/img/liqpay.png"




const PaySlide = () => {
    return (
        <div className='paySlide slide' id='paySlide'>
            <div className="mainContainer">
                <div className="textPart">
                    <h2 className='mb45 text-center'>
                        <FormattedMessage id='easy to pay' defaultMessage='Easy to pay'/>
                    </h2>
                    <div className="payOptionsListHolder">
                        <ul className="payOptionsList">
                            <li className="polItem">
                                <a href="#"><img src={Liqpay} alt=""/></a>
                            </li>
                            <li className="polItem">
                                <a href="#"><img src={Mc} alt=""/></a>
                            </li>
                            <li className="polItem">
                                <a href="#"><img src={Visa} alt=""/></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaySlide