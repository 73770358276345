import React from "react"
import { FormattedMessage } from "react-intl"

const Plans = [
  {
    id: 1,
    active: false,
    name: <FormattedMessage id="starter-pack" defaultMessage="Starter Pack" />,
    price: <FormattedMessage id="starter-price" defaultMessage="91₴/Month" />,
    discount: '129',
    discountPercent: '-30%',
    features: [
      {
        id: "f2",
        text: <FormattedMessage id="sp2" defaultMessage="1000 unique Leads" />,
      },
      {
        id: "f3",
        text: <FormattedMessage id="sp3" defaultMessage="1 Source" />,
      },
      {
        id: "f6",
        text: (
          <FormattedMessage id="sp6" defaultMessage="1 default Alpha name" />
        ),
      },
      {
        id: "f7",
        text: <FormattedMessage id="sp7" defaultMessage="1 Audience" />,
      },
      {
        id: "f8",
        text: (
          <FormattedMessage
            id="sp8"
            defaultMessage="Additional fields managment"
          />
        ),
      },
    ],
  },
  {
    id: 2,
    active: false,
    name: <FormattedMessage id="basic" defaultMessage="Basic" />,
    price: <FormattedMessage id="basic-price" defaultMessage="274₴/Month" />,
    discount: '342',
    discountPercent: '-20%',
    features: [
      {
        id: "f22",
        text: (
          <FormattedMessage id="base2" defaultMessage="2500 unique Leads" />
        ),
      },
      {
        id: "f23",
        text: <FormattedMessage id="base3" defaultMessage="5 Sources" />,
      },
      {
        id: "f26",
        text: <FormattedMessage id="base6" defaultMessage="+1 Alpha name" />,
      },
      {
        id: "f27",
        text: <FormattedMessage id="base7" defaultMessage="3 Audiences" />,
      },
      {
        id: "f28",
        text: (
          <FormattedMessage
            id="base8"
            defaultMessage="Additional fields managment"
          />
        ),
      },
    ],
  },
  {
    id: 3,
    active: false,
    name: <FormattedMessage id="intermediate" defaultMessage="Intermediate" />,
    price: (
      <FormattedMessage id="intermediate-price" defaultMessage="771₴/Month" />
    ),
    discount: '857',
    discountPercent: '-10%',
    features: [
      {
        id: "f32",
        text: <FormattedMessage id="im2" defaultMessage="10 000 Leads" />,
      },
      {
        id: "f33",
        text: <FormattedMessage id="im3" defaultMessage="Unlimited Sources" />,
      },
      {
        id: "f36",
        text: <FormattedMessage id="im6" defaultMessage="+2 Alpha names" />,
      },
      {
        id: "f37",
        text: <FormattedMessage id="im7" defaultMessage="5 Audiences" />,
      },
      {
        id: "ab1",
        text: (
          <FormattedMessage
            id="addbase1"
            defaultMessage="Additional fields managment"
          />
        ),
      },
      {
        id: "ab2",
        text: (
          <FormattedMessage id="addbase2" defaultMessage="Import History" />
        ),
      },
      {
        id: "ab3",
        text: (
          <FormattedMessage
            id="addbase3"
            defaultMessage="Alpha name managment"
          />
        ),
      },
    ],
  },
  {
    id: 4,
    active: false,
    name: <FormattedMessage id="professional" defaultMessage="Professional" />,
    price: (
      <FormattedMessage id="professional-price" defaultMessage="By request" />
    ),
    discount: '',
    discountPercent: '',
    features: [
      {
        id: "f42",
        text: <FormattedMessage id="pro2" defaultMessage="20 000+ Leads" />,
      },
      {
        id: "f43",
        text: <FormattedMessage id="pro3" defaultMessage="Unlimited Sources" />,
      },
      {
        id: "f46",
        text: <FormattedMessage id="pro6" defaultMessage="+5 Alpha names" />,
      },
      {
        id: "f47",
        text: <FormattedMessage id="pro7" defaultMessage="10 Audiences" />,
      },
    ],
    addedFeature: (
      <FormattedMessage
        id="professionalAddedFeature"
        defaultMessage={`+ all from "Intermediate"`}
      />
    ),
  },
]

const PricingPlans = () => {
  return (
    <div className="pricingPlansListHolder">
      <ul className="pricingPlansList">
        {Plans.map(plan => (
          <li
            className={plan.active ? "pplItem active" : "pplItem"}
            key={plan.id}
          >
            <div className="pplItemContent">
              <div className="titlePart">
                <div className="name">{plan.name}</div>
                <div className="price">{plan.price}</div>
              </div>
              <div className="titleDiscount">
                {plan.discount.length > 0 ? (
                    <span className="oldPrice">{plan.discount} <FormattedMessage id="per-month" defaultMessage="₴/Month"/></span>
                ) : (
                    <span className="percent">&nbsp;</span>
                )}
              </div>
              <div
                className={`featListHolder ${
                  plan.addedFeature || plan.additionalFeatures
                    ? "notLastList"
                    : ""
                }`}
              >
                <ul className="featList">
                  {plan.features.map(feature => (
                    <li className="flItem" key={feature.id}>
                      {feature.text}
                    </li>
                  ))}
                </ul>
              </div>

              {plan.addedFeature && (
                <div className="featListHolder">
                  <div className="featList">{plan.addedFeature}</div>
                </div>
              )}
              {plan.additionalFeatures && (
                <div className="featListHolder">
                  <div className="featList">
                    <FormattedMessage
                      id="additional"
                      defaultMessage="Additional:"
                    />
                  </div>
                  <div className="featListHolder">
                    <ul className="featList">
                      {plan.additionalFeatures.map(feature => (
                        <li className="flItem" key={feature.id}>
                          {feature.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {/* <div className="btnHolder">
                <a href="#" className="btn btnPrimary contained">
                  <FormattedMessage id="choose" defaultMessage="Choose" />
                </a>
              </div> */}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PricingPlans
