import Helmet from "react-helmet"
import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import IntroSlide from "../components/landing/intro-slide/intro-slide.component"
import FeaturesSlide from "../components/landing/features-slide/features-slide.component"
import FactsSlide from "../components/landing/facts-slide/facts-slide.component"
import AboutSlide from "../components/landing/about-slide/about-slide.component"
import AdvSlide from "../components/landing/advantages-slide/advantages-slide.component"
// import LinkTranslated from "../components/localization/link-translated.component";
import Localize from "../components/localization/intl-provider.component"
import PricingSlide from "../components/landing/pricing-slide/pricing-slide.component"
import PaySlide from "../components/landing/pay-slide/pay-slide.component"

const uaTitle = "Надішліть СМС повідомлення точно в ціль!"
const uaDesc =
  "Простий і функціональний інструмент, що збільшує продажі та економить дорогоцінний час на розвиток вашого бізнесу"

const IndexPage = props => {
  return (
    <Localize {...props}>
      <Layout>
        <SEO title={uaTitle} description={uaDesc} />
        <IntroSlide />
        <AboutSlide />
        <FeaturesSlide />
        <FactsSlide />
        <PricingSlide />
        <AdvSlide />
        <PaySlide />
      </Layout>
    </Localize>
  )
}

export default IndexPage
