import React from "react"
import Scr1 from "../../../assets/img/3.png"
import {FormattedMessage} from "react-intl";



const AdvSlide = () => {
    return (
        <div className='advantagesSlide slide' id='advSlide'>
            <div className="mainContainer">
                <div className="slideWrapper">
                    <div className="textPart">
                        <div className="subTitle mb15">
                            <FormattedMessage id='advantages' defaultMessage='Advantages'/>
                        </div>
                        <h1 className='mb15'>
                            <FormattedMessage id='how-your-business' defaultMessage='How your business can succeed with Leads'/>
                        </h1>
                        <div className="imgHolder mobile">
                            <img src={Scr1} alt=""/>
                        </div>
                        <p className='mb30'>
                            <FormattedMessage id='sms-is-the-most' defaultMessage='SMS is the most effective way to reach your customers. Why? Enjoy the fact that your message was delivered right away and that 95% of customers are reading it within 3 minutes.'/>
                        </p>
                        <h3 className="textPrimary mb15">
                            <FormattedMessage id='marketing-and-sales' defaultMessage='Marketing & Sales'/>
                        </h3>
                        <ul className='textGray mb30'>
                            <li>
                                <FormattedMessage id='identify-extremely-involved' defaultMessage='Identify extremely involved customers and build a special audience'/>
                            </li>
                            <li>
                                <FormattedMessage id='automate-your-sales' defaultMessage='Automate your sales outreach campaigns'/>
                            </li>
                            <li>
                                <FormattedMessage id='use-audience-and-tags' defaultMessage='Use audience and tags to make your campaigns unique to each customer'/>
                            </li>
                            <li>
                                <FormattedMessage id='convert-extra-customers' defaultMessage='Convert extra customers, and increase your sales.'/>
                            </li>
                        </ul>
                        <h3 className="textPrimary mb15">
                            <FormattedMessage id='business-development' defaultMessage='Business Development'/>
                        </h3>
                        <p className="textGray mb30">
                            <FormattedMessage id='use-leads-to-succeed' defaultMessage='Use Leads to succeed in getting out a lot of potential business partners, and build more strategic partnerships for your business.'/>
                        </p>
                        <h3 className="textPrimary mb15">
                            <FormattedMessage id='hr-and-recruiting' defaultMessage='HR and Recruiting'/>
                        </h3>
                        <ul className='textGray'>
                            <li>
                                <FormattedMessage id='speed-up-and-scale' defaultMessage='Speed up and scale your hiring strategies'/>
                            </li>
                            <li>
                                <FormattedMessage id='immediately-send-a-text' defaultMessage='Immediately send a text campaign to the groups of candidates.'/>
                            </li>
                        </ul>
                    </div>
                    <div className="imgPart">
                        <div className="imgHolder desktop">
                            <img src={Scr1} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvSlide