import React from "react"
import Fli1 from '../../../assets/img/fli1.svg'
import Fli2 from '../../../assets/img/fli2.svg'
import Fli3 from '../../../assets/img/fli3.svg'
import Fli4 from '../../../assets/img/fli4.svg'
import Fli5 from '../../../assets/img/fli5.svg'
import Fli6 from '../../../assets/img/fli6.svg'
import Fli7 from '../../../assets/img/fli7.svg'
import Fli8 from '../../../assets/img/fli8.svg'
import {FormattedMessage} from "react-intl";

const Features = [
    {
        id: 1,
        img: Fli1,
        title: <FormattedMessage id='immediate-delivery' defaultMessage='Immediate Delivery'/>,
        text: <FormattedMessage id='send-fast' defaultMessage='Send fast and easy messages that direct customers back to you.'/>
    },
    {
        id: 2,
        img: Fli2,
        title: <FormattedMessage id='personalize-communication' defaultMessage='Personalize Communication'/>,
        text: <FormattedMessage id='increase-involvement-with' defaultMessage='Increase involvement with effective automated outreach.'/>
    },
    {
        id: 3,
        img: Fli3,
        title: <FormattedMessage id='detailed-analytics' defaultMessage='Detailed analytics'/>,
        text: <FormattedMessage id='track-everything' defaultMessage='Track everything: Clicks on the link, message delivery and much more.'/>
    },
    {
        id: 4,
        img: Fli4,
        title: <FormattedMessage id='manage-leads-easily' defaultMessage='Manage Leads Easily'/>,
        text: <FormattedMessage id='segment-your-leads' defaultMessage='Segment your leads properly using smart search and flexible filters.'/>
    },
    {
        id: 5,
        img: Fli5,
        title: <FormattedMessage id='phone-number-validation' defaultMessage='Phone Number Validation'/>,
        text: <FormattedMessage id='identify-incorrect' defaultMessage='Identify incorrect phone numbers and carriers right away.'/>
    },
    {
        id: 6,
        img: Fli6,
        title: <FormattedMessage id='set-up-delays' defaultMessage='Set up delays'/>,
        text: <FormattedMessage id='set-up-the-proper' defaultMessage='Set up the proper delay between messages in your campaign.'/>
    },
    {
        id: 7,
        img: Fli7,
        title: <FormattedMessage id='export-leads-segments' defaultMessage='Export leads segments'/>,
        text: <FormattedMessage id='this-saves' defaultMessage='This saves you myriad hours of manually transferring information.'/>
    },
    {
        id: 8,
        img: Fli8,
        title: <FormattedMessage id='schedule-message-for-any-time' defaultMessage='Schedule message for any time'/>,
        text: <FormattedMessage id='deliver-a-message' defaultMessage='Deliver a message at a suitable time, across any time zone.'/>
    }
]

const FeaturesSlide = () => {
    return (
        <div className='featuresSlide slide' id='keySlide'>
            <div className="mainContainer">
                <div className="subTitle mb15"><FormattedMessage id='key-features' defaultMessage='Key Features'/></div>
                <h1 className='mb30'>
                    <FormattedMessage id='these-features' defaultMessage='These features are all just one click away'/>
                </h1>
                <div className="btnHolder">
                    <a href="https://app.leads.com.ua/register" rel="noreferrer" target='_blank' className="btn btnPrimary contained">
                        <FormattedMessage id='start-today' defaultMessage='Start Today'/>
                    </a>
                </div>
                <div className="featuresListHolder">
                    <ul className="featuresList">
                        {
                            Features.map((item) =>
                                <li className="flItem" key={item.id}>
                                    <div className="icoHolder"><img src={item.img} alt={item.title + ' Icon'}/></div>
                                    <div className="textHolder">
                                        <div className="title">{item.title}</div>
                                        <div className="text">{item.text}</div>
                                    </div>
                                </li>
                            )
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
}


export default FeaturesSlide
